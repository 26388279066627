import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './common/http'

const app = createApp(App,{
    // 禁用开发模式
    productionTip: true
})

// vue3全局挂载axios
app.config.globalProperties.$api = axios;
app.config.globalProperties.$url = "https://agrtj.com/";
app.config.globalProperties.$wxurl = "https://mp.quanjidj.cn/";
app.use(store).use(router).mount('#app')
