import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from "../views/serviceArea.vue"
import Reserve from "../views/reserve.vue"
import Reserved from "../views/reserved.vue"
import ReserveShose from "../views/reserveShose.vue"
import ReserveCup from "../views/reserveCup.vue"
import ReserveZh from "../views/reserveZh.vue"
import Serve from "../views/serveComplete.vue"
import Sign from "../views/sign.vue"
import Accord from "../views/accord.vue"

const routes: Array<RouteRecordRaw> = [
  { // 查看地址页面
    path: "/",
    name: "home",
    component: Home,
    meta: {
      keepAlive: false
    }
  },
  { // 预约页面
    path: "/u/:su",
    name: "reserve",
    component: Reserve,
    meta: {
      keepAlive: false
    }
  },
  { // 已预约页面
    path: "/reserved/:su",
    name: "reserved",
    component: Reserved,
    meta: {
      keepAlive: false
    }
  },
  { //  鞋靴预约页面
    path: "/shose/:su",
    name: "reserveShose",
    component: ReserveShose,
    meta: {
      keepAlive: false
    }
  },
  { //  招行预约页面
    path: "/zh/:su",
    name: "reserveZh",
    component: ReserveZh,
    meta: {
      keepAlive: false
    }
  },
  { // 杯子礼品预约页面
    path: "/cup/:su",
    name: "reserveCup",
    component: ReserveCup,
    meta: {
      keepAlive: false
    }
  },
  { // 完成服务页面
    path: "/serve",
    name: "serve",
    component: Serve,
    meta: {
      keepAlive: false
    }
  },
  { // 签名页面
    path: "/sign",
    name: "sign",
    component: Sign,
    meta: {
      keepAlive: false
    }
  },
  { // 保价协议页面
    path: "/accord",
    name: "accord",
    component: Accord,
    meta: {
      keepAlive: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
