
// Toast
import { Toast } from 'vant';
import 'vant/es/toast/style';

// Notify
import { Notify } from 'vant';
import 'vant/es/notify/style';

// Dialog
import { Dialog } from 'vant';
import 'vant/es/dialog/style';

const toast = (type:string,msg:any,time:number)=>{
    if(type=='loading'){
        Toast.loading({
            duration: time,
            forbidClick: true,
            message: '正在提交...',
        });
    }else if(type=='clear'){
        Toast.clear();
    }else{
        Toast({
            message:msg,
            duration: time ? time : 2000,
        });
    }
}

const notify = (type:any,message:any)=>{
    Notify({ 
        type,
        message
    });
}
const dialog = (title:any, msg:any, type:any)=>{
    const message = msg + '<br><span style="line-height: 30px;padding: 5px 20px;font-size: 12px;border: 1px solid #b50029;color: #b50029;border-radius: 5px !important;background: #fff;">服务热线：<a style="color: #b50029;" href="tel:40066-31115"> 40066-31115 </a></span>';
    Dialog.alert({ 
        title,
        message,
        allowHtml:true
    }).then(() => {
        if(type==1){
            window.history.go(-1);
        }else if(type==3){
            setTimeout(() => {
                location.reload()
            }, 1000);
        }
    });
}

export default {toast,notify,dialog}