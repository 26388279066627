import axios from 'axios'
import { Toast } from 'vant';
import 'vant/es/toast/style';
// import qs from "qs";//qs为序列化数据的js库

//设置baseUrl
// axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "https://agrtj.com" : "/api";

//设置请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//设置超时
// axios.defaults.timeout = 10000;
//请求拦截器
axios.interceptors.request.use(
    config => {      
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        Toast('异常请求')
    }
);

//封装post/get请求
export default {
    post(url:any, data:any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data,
            }).then((result:any) => {
                if(result){
                    resolve(result.data)
                }else{
                    Toast('异常请求')
                }
            })
            .catch(err => {
                reject(err)
            });
        })
    },
    get(url:any, data:any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                params: data,
            }).then((result:any) => {
                resolve(result.data)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
};
